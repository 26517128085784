import React, { useState, useEffect } from "react";
import { FaEthereum } from "react-icons/fa6";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { ethers } from "ethers";
import tokenAbi from "../abi/tokenABI.json";
import stakeAbi from "../abi/stakeABI.json";
import { toast } from "react-toastify";
import setting from "./settings.json";

function Withdraw() {
  const { walletProvider } = useWeb3ModalProvider();

  const [updateData, setUpdateData] = useState(false);

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const [symbol, setSymbol] = useState("");
  const [staked, setStaked] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");

  const withdrawTokens = async () => {
    if (withdrawAmount === "") {
      toast.error("Enter amount to stake");
      return;
    }

    if (Number(withdrawAmount) > Number(staked)) {
      toast.error("Withdraw amount greater than staked");
      return;
    }
    let toastId = null;
    try {
      if (isConnected) {
        const provider = new ethers.BrowserProvider(walletProvider);

        const signer = await provider.getSigner();

        const stakingContract = new ethers.Contract(
          setting.STAKING_CONTRACT,
          JSON.parse(stakeAbi.result),
          provider
        );

        const amountWei = ethers.parseEther(withdrawAmount);

        toastId = toast.loading(`Transaction Processing`);

        const withdrawTransaction = await stakingContract
          .connect(signer)
          .withdraw(amountWei);

        // console.log(withdrawTransaction, "wipwir-1");

        const txn = await withdrawTransaction.wait();

        toast.update(toastId, {
          render: `Transaction Successful ${txn.hash}`,
          type: "success",
          isLoading: false,
          autoClose: 3000,
          draggable: true,
        });

        setTimeout(() => {
          setUpdateData(!updateData);
          setWithdrawAmount("");
        }, 3000);
      }
    } catch (err) {
      console.log(err);
      toast.update(toastId, {
        render: `${err}`,
        type: "error",
        isLoading: false,
        autoClose: 3000,
        draggable: true,
      });
    }
  };

  async function fetchData() {
    if (isConnected) {
      const provider = new ethers.BrowserProvider(walletProvider);

      const tokenContract = new ethers.Contract(
        setting.TOKEN_CONTRACT,
        JSON.parse(tokenAbi.result),
        provider
      );

      const stakingContract = new ethers.Contract(
        setting.STAKING_CONTRACT,
        JSON.parse(stakeAbi.result),
        provider
      );

      const symbol = await tokenContract.symbol();

      const stakedAmount = await stakingContract.getStaked(address);
      setStaked(ethers.formatUnits(stakedAmount, 18));

      setSymbol(symbol);
    }
  }

  useEffect(() => {
    fetchData();
  }, [isConnected, updateData]);

  return (
    <div className="pt-[140px]">
      <div className="container px-4 mx-auto justify-center flex flex-col items-center">
        <h5 className="mb-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white mb-4">
          Withdrawals
        </h5>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 mb-4">
          Request SPCT withdrawal
        </p>
        <div
          className="max-w-xl w-full border-0 rounded-xl shadow bg-[#202121c7] dark:border-[#5d5d5d] mt-4"
          style={{ backdropFilter: "blur(6px)" }}
        >
          <div className="p-8">
            {isConnected ? (
              <div className="flex justify-between mb-8 items-center">
                <div className="flex flex-col">
                  <div className="text-[#b6b6b6]">Available to withdraw</div>
                  <div className="text-white text-xl mt-2">
                    {staked} {symbol}
                  </div>
                </div>
                <w3m-account-button balance={false} />
              </div>
            ) : (
              <></>
            )}

            {isConnected ? <hr style={{ borderColor: "#5d5d5d" }} /> : <></>}

            <div className="relative mt-8">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <FaEthereum className="text-gray-500" />
              </div>
              <input
                type="search"
                id="amount"
                className="block w-full p-4 ps-10 text-sm text-white  border-0 rounded-lg bg-[#121212a8] focus:ring-none focus:border-none "
                placeholder={`${symbol} Amount`}
                required
                value={withdrawAmount}
                autoComplete="off"
                onChange={(e) => setWithdrawAmount(e.target.value)}
              />
              <button
                className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-[#000000cc]  focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-[#000000cc] dark:hover:bg-[#000000cc] dark:focus:ring-none"
                onClick={(e) => setWithdrawAmount(staked)}
              >
                Max
              </button>
            </div>

            {isConnected ? (
              <button
                className="flex w-full mt-4 text-xl justify-center items-center text-center px-3 py-4 text-sm font-medium text-center text-white rounded-lg hover:bg-blue-800 focus:outline-none focus:ring-blue-300 dark:bg-[#000000cc] dark:hover:bg-[#000000cc] dark:focus:ring-none"
                onClick={withdrawTokens}
              >
                Request Withdrawal
              </button>
            ) : (
              <div className="flex w-full mt-4 text-xl justify-center items-center text-center ">
                <w3m-button />
              </div>
            )}

            {/* <div className="mt-6 flex items-center justify-between text-sm font-semibold">
              <div className="flex ml-1 text-[#b6b6b6]">Max unlock cost</div>
              <div className="flex items-center text-[#b6b6b6]">FREE</div>
            </div>

            <div className="mt-6 flex items-center justify-between text-sm font-semibold text-gray-900">
              <div className="flex ml-1 text-[#b6b6b6]">
                Max transaction cost
              </div>
              <div className="flex items-center text-[#b6b6b6]">$15.59</div>
            </div> */}

            {/* <div className="mt-6 flex items-center justify-between text-sm font-semibold text-gray-900">
              <div className="flex ml-1 text-[#b6b6b6]">Stakers</div>
              <div className="flex items-center text-[#b6b6b6]">408344</div>
            </div>

            <div className="mt-6 flex items-center justify-between text-sm font-semibold text-gray-900">
              <div className="flex ml-1 text-[#b6b6b6]">stETH market cap</div>
              <div className="flex items-center text-[#b6b6b6]">
                $28,754,169,405
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Withdraw;
