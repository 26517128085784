import Navbar from "./components/Navbar";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Stake from "./components/Stake";
import Rewards from "./components/Rewards";
import Withdraw from "./components/Withdraw";
import bgVid from "./assets/bg.mp4";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const projectId = "b256a0364558ec9fe39d8d051478fc99";

  const mainnet = {
    chainId: 1,
    name: "Ethereum",
    currency: "ETH",
    explorerUrl: "https://etherscan.io",
    rpcUrl: "https://mainnet.infura.io/v3/7770feb125b24c6699043bded4c53e7f",
  };
  const sepolia = {
    chainId: 11155111,
    name: "Sepolia Testnet",
    currency: "SepoliaETH",
    explorerUrl: "https://sepolia.etherscan.io",
    rpcUrl: "https://ethereum-sepolia-rpc.publicnode.com",
  };

  const metadata = {
    name: "Spectrachain",
    description: "Bitcoin Security, Ethereum Flexibility: Spectra Chain, Shaping the Future of Decentralization.",
    url: "https://spectrachain.io/", 
    icons: ["https://spectrascan.io/assets/network_logo_dark.png"],
  };

  const ethersConfig = defaultConfig({
    metadata,
    enableEIP6963: true, // true by default
    enableInjected: true, // true by default
    enableCoinbase: false, // true by default
    defaultChainId: 1,
  });

  createWeb3Modal({
    ethersConfig,
    chains: [mainnet],
    projectId,
    enableAnalytics: false,
    themeVariables: {
      "--w3m-accent": "#121212",
      "--w3m-border-radius-master": "1px",
    },
    featuredWalletIds: [
      "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
    ],
    allWallets: "HIDE",
  });

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        draggable
        pauseOnHover
        theme="dark"
      />
      <Router>
        <video
          className="videoTag"
          autoPlay
          loop
          muted
          style={{
            zIndex: -1,
            position: "fixed",
            height: "100%",
            width: "100%",
            top: 0,
            objectFit: "cover",
            filter: "grayscale(1)",
          }}
        >
          <source src={bgVid} type="video/mp4" />
        </video>
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Stake />} />
          <Route path="/withdraw" exact element={<Withdraw />} />
          <Route path="/rewards" exact element={<Rewards />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
