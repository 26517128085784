import React, { useEffect, useState } from "react";
import { FaEthereum } from "react-icons/fa6";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { ethers } from "ethers";
import tokenAbi from "../abi/tokenABI.json";
import stakeAbi from "../abi/stakeABI.json";
import { toast } from "react-toastify";
import setting from "./settings.json";

function Stake() {
  const { walletProvider } = useWeb3ModalProvider();

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const [availBal, setAvailBal] = useState("");
  const [symbol, setSymbol] = useState("");
  const [staked, setStaked] = useState("");
  const [stakingAmount, setStakingAmount] = useState("");

  const [rewardPerDay, setRewardPerDay] = useState(0);

  const [updateData, setUpdateData] = useState(false);

  const [userStaked, setUserStaked] = useState("0");
  const [totalAmountStaked, setTotalAmountStaked] = useState(0.0);

  const [allowance, setAllowance] = useState("");

  function calculateAPR(totalValueStaked) {
    if (Number(totalValueStaked) === 0.0) {
      return 0;
    }

    const totalRewardsPerYear = rewardPerDay * 365;

    const apr = (totalRewardsPerYear / totalValueStaked) * 100;

    return apr.toFixed(2);
  }

  async function handleAllow() {
    let toastId = null;
    try {
      if (isConnected) {
        const provider = new ethers.BrowserProvider(walletProvider);
        const signer = await provider.getSigner();

        const tokenContract = new ethers.Contract(
          setting.TOKEN_CONTRACT,
          JSON.parse(tokenAbi.result),
          provider
        );

        const amountWei = ethers.parseEther(stakingAmount);

        toastId = toast.loading(`Transaction Processing`);

        const approveTransaction = await tokenContract
          .connect(signer)
          .approve(setting.STAKING_CONTRACT, amountWei);

        const txn = await approveTransaction.wait();

        toast.update(toastId, {
          render: `Transaction Successful ${txn.hash}`,
          type: "success",
          isLoading: false,
          autoClose: 3000,
          draggable: true,
        });

        setTimeout(() => {
          setUpdateData(!updateData);
        }, 3000);
      }
    } catch (err) {
      console.error("Error staking:", err);
      toast.update(toastId, {
        render: `${err}`,
        type: "error",
        isLoading: false,
        autoClose: 3000,
        draggable: true,
      });
    }
  }

  async function handleStaking() {
    if (stakingAmount === "") {
      toast.error("Enter amount to stake");
      return;
    }

    if (Number(stakingAmount) > Number(availBal)) {
      toast.error("Staking amount greater than available balance");
      return;
    }

    let toastId = null;
    try {
      if (isConnected) {
        const provider = new ethers.BrowserProvider(walletProvider);
        const signer = await provider.getSigner();

        const amountWei = ethers.parseEther(stakingAmount);

        const stakingContract = new ethers.Contract(
          setting.STAKING_CONTRACT,
          JSON.parse(stakeAbi.result),
          provider
        );

        toastId = toast.loading(`Transaction Processing`);

        const stakeTransaction = await stakingContract
          .connect(signer)
          .stake(amountWei);

        const txn = await stakeTransaction.wait();

        toast.update(toastId, {
          render: `Transaction Successful ${txn.hash}`,
          type: "success",
          isLoading: false,
          autoClose: 3000,
          draggable: true,
        });

        setTimeout(() => {
          setUpdateData(!updateData);
          setStakingAmount("");
        }, 3000);
      }
    } catch (err) {
      console.error("Error staking:", err);
      toast.update(toastId, {
        render: `${err}`,
        type: "error",
        isLoading: false,
        autoClose: 3000,
        draggable: true,
      });
    }
  }

  async function fetchData() {
    if (isConnected) {
      const provider = new ethers.BrowserProvider(walletProvider);

      const tokenContract = new ethers.Contract(
        setting.TOKEN_CONTRACT,
        JSON.parse(tokenAbi.result),
        provider
      );

      const stakingContract = new ethers.Contract(
        setting.STAKING_CONTRACT,
        JSON.parse(stakeAbi.result),
        provider
      );

      const balance = await tokenContract.balanceOf(address);

      const allow = await tokenContract.allowance(
        address,
        setting.STAKING_CONTRACT
      );

      setAllowance(ethers.formatUnits(allow, 18));

      const symbol = await tokenContract.symbol();

      const stakedAmount = await stakingContract.getStaked(address);
      setStaked(ethers.formatUnits(stakedAmount, 18));

      const rewardPerDay = await stakingContract.TOKENS_PER_DAY();
      setRewardPerDay(Number(rewardPerDay));

      const usrStaked = await stakingContract.totalUsersStaked();
      setUserStaked(Number(usrStaked));

      const amtStaked = await stakingContract.staked_totalSupply();
      setTotalAmountStaked(Number(ethers.formatUnits(amtStaked, 18)));

      setSymbol(symbol);
      console.log(ethers.formatUnits(balance, 18), "bal user");
      setAvailBal(ethers.formatUnits(balance, 18));
    }
  }

  useEffect(() => {
    fetchData();
  }, [isConnected, updateData]);

  return (
    <div className="pt-[140px]">
      <div className="container px-4 mx-auto justify-center flex flex-col items-center">
        <h5 className="mb-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white mb-4">
          Stake Spectra
        </h5>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 mb-4">
          Stake Spectra and receive Spectra while staking
        </p>
        <div
          className="max-w-xl w-full border-0 rounded-xl shadow bg-[#202121c7] dark:border-[#5d5d5d] mt-4 mb-10"
          style={{ backdropFilter: "blur(6px)" }}
        >
          <div className="p-8">
            {isConnected ? (
              <div className="flex justify-between mb-8 items-center">
                <div className="flex flex-col">
                  <div className="text-[#b6b6b6]">Available to stake</div>
                  <div className="text-white text-xl mt-2">
                    {availBal} {symbol}
                  </div>
                </div>
                <w3m-account-button balance={false} />
              </div>
            ) : (
              <></>
            )}

            {isConnected ? <hr style={{ borderColor: "#5d5d5d" }} /> : <></>}

            {isConnected ? (
              <div className="flex justify-between mb-8 items-center mt-8">
                <div className="flex flex-col">
                  <div className="text-[#b6b6b6]">Staked Amount</div>
                  <div className="text-white text-xl mt-2">
                    {staked} {symbol}
                  </div>
                </div>

                <div className="flex flex-col mr-2">
                  <div className="text-[#b6b6b6]">Spectra APR</div>
                  <div className="text-white text-xl mt-2">
                    {calculateAPR(totalAmountStaked)} %
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <FaEthereum className="text-gray-500" />
              </div>
              <input
                type="search"
                id="amount"
                className="block w-full p-4 ps-10 text-sm text-white  border-0 rounded-lg bg-[#121212a8] focus:ring-none focus:border-none "
                placeholder={`${symbol} Amount`}
                required
                value={stakingAmount}
                autoComplete="off"
                onChange={(e) => setStakingAmount(e.target.value)}
              />
              <button
                className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-[#000000cc]  focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-[#000000cc] dark:hover:bg-[#000000cc] dark:focus:ring-none"
                onClick={(e) => setStakingAmount(availBal)}
              >
                Max
              </button>
            </div>

            {isConnected ? (
              <div>
                {Number(stakingAmount) > Number(allowance) && (
                  <button
                    className="flex w-full mt-4 text-xl justify-center items-center text-center px-3 py-4 text-sm font-medium text-center text-white rounded-lg hover:bg-blue-800 focus:outline-none focus:ring-blue-300 dark:bg-[#000000cc] dark:hover:bg-[#000000cc] dark:focus:ring-none"
                    onClick={handleAllow}
                  >
                    Approve
                  </button>
                )}

                {Number(stakingAmount) <= Number(allowance) && (
                  <button
                    className="flex w-full mt-4 text-xl justify-center items-center text-center px-3 py-4 text-sm font-medium text-center text-white rounded-lg hover:bg-blue-800 focus:outline-none focus:ring-blue-300 dark:bg-[#000000cc] dark:hover:bg-[#000000cc] dark:focus:ring-none"
                    onClick={handleStaking}
                  >
                    Stake
                  </button>
                )}
              </div>
            ) : (
              <div className="flex w-full mt-4 text-xl justify-center items-center text-center ">
                {" "}
                <w3m-button />
              </div>
            )}

            <div className="mt-6 flex items-center justify-between text-sm font-semibold">
              <div className="flex ml-1 text-[#b6b6b6]">
                Annual percentage rate
              </div>
              <div className="flex items-center text-[#b6b6b6]">
                {calculateAPR(totalAmountStaked)} %
              </div>
            </div>

            <div className="mt-6 flex items-center justify-between text-sm font-semibold text-gray-900">
              <div className="flex ml-1 text-[#b6b6b6]">Total staked</div>
              <div className="flex items-center text-[#b6b6b6]">
                {totalAmountStaked.toFixed(4)} {symbol}
              </div>
            </div>

            <div className="mt-6 flex items-center justify-between text-sm font-semibold text-gray-900">
              <div className="flex ml-1 text-[#b6b6b6]">Stakers</div>
              <div className="flex items-center text-[#b6b6b6]">
                {userStaked}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stake;
