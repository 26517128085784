import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { MdContentCopy } from "react-icons/md";
import tokenAbi from "../abi/tokenABI.json";
import stakeAbi from "../abi/stakeABI.json";
import { FaEthereum } from "react-icons/fa6";
import { toast } from "react-toastify";
import setting from "./settings.json";

function Rewards() {
  const [updateData, setUpdateData] = useState(false);

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const [symbol, setSymbol] = useState("");
  const [staked, setStaked] = useState("");
  const [earned, setEarned] = useState("");

  const [claimedRewardAmount, setClaimRewardAmount] = useState("");

  const { walletProvider } = useWeb3ModalProvider();

  async function fetchData() {
    if (isConnected) {
      const provider = new ethers.BrowserProvider(walletProvider);

      const tokenContract = new ethers.Contract(
        setting.TOKEN_CONTRACT,
        JSON.parse(tokenAbi.result),
        provider
      );

      const stakingContract = new ethers.Contract(
        setting.STAKING_CONTRACT,
        JSON.parse(stakeAbi.result),
        provider
      );

      const symbol = await tokenContract.symbol();

      const stakedAmount = await stakingContract.getStaked(address);
      setStaked(ethers.formatUnits(stakedAmount, 18));

      const userRewards = await stakingContract.earned(address);
      setEarned(ethers.formatUnits(userRewards, 18));

      const rewardsClaimed = await stakingContract._userClaimedRewards(address);
      setClaimRewardAmount(ethers.formatUnits(rewardsClaimed, 18));

      setSymbol(symbol);
    }
  }

  const claimRewards = async () => {
    let toastId = null;
    try {
      if (isConnected) {
        const provider = new ethers.BrowserProvider(walletProvider);

        const signer = await provider.getSigner();

        const stakingContract = new ethers.Contract(
          setting.STAKING_CONTRACT,
          JSON.parse(stakeAbi.result),
          provider
        );

        toastId = toast.loading(`Transaction Processing`);

        const claimTransaction = await stakingContract
          .connect(signer)
          .claimReward();

        const txn = await claimTransaction.wait();

        toast.update(toastId, {
          render: `Transaction Successful ${txn.hash}`,
          type: "success",
          isLoading: false,
          autoClose: 3000,
          draggable: true,
        });

        setTimeout(() => {
          setUpdateData(!updateData);
        }, 3000);
      }
    } catch (err) {
      toast.update(toastId, {
        render: `${err}`,
        type: "error",
        isLoading: false,
        autoClose: 3000,
        draggable: true,
      });
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [isConnected, updateData]);

  return (
    <div className="pt-[140px]">
      <div className="container mx-auto px-4 justify-center flex flex-col items-center">
        <h5 className="mb-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white mb-4">
          Rewards
        </h5>
        <p className="mb-3 font-normal text-gray-400 mb-4">
          Track your Spectra staking rewards
        </p>

        <div
          className="max-w-4xl w-full  border-0 rounded-xl  mb-10 shadow bg-[#202121c7]"
          style={{ backdropFilter: "blur(6px)" }}
        >
          <div className="px-8 mb-2 mt-6">
            <input
              type="text"
              id="waller address"
              className="bg-[#2c2c2c]  border-0 text-gray-400 text-sm rounded-lg  block w-full p-4"
              placeholder="Ethereum address"
              required
              autoComplete="off"
              disabled={true}
              value={isConnected ? address : ""}
            />
            {isConnected && (
              <div className="absolute right-[50px] cursor-pointer top-[40px]">
                <MdContentCopy className="text-xl text-gray-400" />
              </div>
            )}
          </div>

          {isConnected && (
            <div className="grid  grid-cols-1 lg:grid-cols-3  gap-8 p-4  mx-auto text-gray-900  dark:text-white sm:p-8">
              <div className="flex flex-col items-center justify-center">
                <dt className="mb-2 text-xl font-semibold"> Amount staked</dt>
                <dd className="text-gray-500 text-lg dark:text-gray-400">
                  {staked} {symbol}
                </dd>
              </div>
              <div className="flex flex-col items-center justify-center">
                <dt className="mb-2 text-xl font-semibold"> Amount rewarded</dt>
                <dd className="text-gray-500 text-lg dark:text-gray-400">
                  {earned} {symbol}
                </dd>
              </div>

              <div className="flex flex-col items-center justify-center">
                <dt className="mb-2 text-xl font-semibold"> Amount claimed</dt>
                <dd className="text-gray-500 text-lg dark:text-gray-400">
                  {claimedRewardAmount} {symbol}
                </dd>
              </div>
            </div>
          )}

          <div className="px-12 mb-4 mt-4">
            {isConnected ? (
              <button
                className="flex w-full mt-4 text-xl justify-center items-center text-center px-3 py-4 text-sm font-medium text-center text-white rounded-lg hover:bg-blue-800 focus:outline-none focus:ring-blue-300 dark:bg-[#000000cc] dark:hover:bg-[#000000cc] dark:focus:ring-none"
                onClick={claimRewards}
              >
                <FaEthereum className="text-gray-500 mr-2" />
                {`Claim ${earned - claimedRewardAmount}`}
              </button>
            ) : (
              <div className="flex justify-center">
                {" "}
                <w3m-button />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Rewards;
